import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vaccination-certificate',
  templateUrl: './vaccination-certificate.component.html'
})
export class VaccinationCertificateComponent implements OnInit {
  @Input() certificateDetails: any = {};
  ngOnInit(): void {}
}
