<div class="content">
  <div >
    <div class="text-center pt-4 logo">
      <img src="./assets/images/PawsNme_App_first_page.png" alt="PawsNme_Logo" width="100px" />
    </div>
    <div class="text-center title">
      <div class="heading" style="display: flex; justify-content: space-between; align-items: center; background-color: rgb(181, 221, 255); padding: 10px;" >
        <p></p>
        <h6 style="background-color: rgb(181, 221, 255)">Subscription Renewal of <p style="color: blue;display: inline; background-color: rgb(181, 221, 255); ">{{doctorProfile?.clinics[0].clinic.name}}</p></h6>
        <p style="background-color: rgb(181, 221, 255); cursor: pointer; color: rgb(51, 51, 252); text-align: right" (click)="logout()">Logout</p>
      </div>
      <br />
      <img src="./assets/images/warning.png" alt="warning_icon" style="width: 25px; padding-bottom: 10px;"><h2 style=" display: inline;">&nbsp;Your Subscription Has Expired</h2>
      <br />
      <img src="./assets/images/sick-dog.png" alt="" width="80px" />
      <br />
      <h5>Dear Dr. {{doctorProfile?.first_name}} {{doctorProfile?.last_name}}</h5>
      <div class="text-center" style="font-size: 14px; margin: 20px">
        <h6>Your PawsNme <p style="color: blue; display: inline;">{{doctorProfile?.plan.name}}</p> subscription has been expired.</h6>
        <p>Please select any subscription plan from below to make payment and enjoy our uniterrupted service.</p>
      </div>
      <div class="plans-container text-center">
        <div class="plan" *ngFor="let plan of plans">
          <div style="height: 90%; padding: 10px">
            <h3>{{ plan.title }}</h3>
            <br />
            <h2>{{ plan.cost }}</h2>
            <p style="font-size: 12px">+ 18% GST</p>
            <br />
            <ul>
              <li *ngFor="let details of plan.details">{{ details }}</li>
            </ul>
            <div *ngIf="plan.title === 'PROFESSIONAL'" style="font:12px; color:blue; padding-top: 20px;" >
              <p>
              # Appointment Chargeable per ticket
            </p>
            </div>
            
          </div>
          <button class="subscribe-button">Subscribe</button>
        </div>
      </div>
    </div>
    <div class="footer">
        <p style="font-size: 14px; padding: 10px 30px;">For any query, Mail us: <strong> reach@pawsnme.com</strong> or Call us: <strong> +918884543595 </strong> </p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
