import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../extra-module/auth-intercept/auth-guard.service';
import { MainLayoutComponent } from '../shared-module/main-layout/main-layout.component';
import { CertificateDashboardComponent } from './certificate-dashboard/certificate-dashboard.component';
import { CreateCertificateComponent } from './create-certificate/create-certificate.component';
import { EuthenesiaConsentFormComponent } from './euthenesia-consent-form/euthenesia-consent-form.component';
import { HealthCertificateComponent } from './health-certificate/health-certificate.component';
import { MicrochipCertificateComponent } from './microchip-certificate/microchip-certificate.component';
import { PetTravelCertificateComponent } from './pet-travel-certificate/pet-travel-certificate.component';
import { SpayedCertificateComponent } from './spayed-certificate/spayed-certificate.component';
import { SurgeryCertificateComponent } from './surgery-certificate/surgery-certificate.component';
import { VaccinationCertificateComponent } from './vaccination-certificate/vaccination-certificate.component';
import { CertificateTemplateComponent } from './certificate-template/certificate-template.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'list',
        component: CertificateDashboardComponent,
        canActivate: [AuthGuardService],
        data: { title: 'Pet Certificate List', enableExtraHeader: false, pageAccess: 'All' }
      },
      {
        path: 'create',
        component: CreateCertificateComponent,
        canActivate: [AuthGuardService],
        data: { title: 'Create Pet Certificate', enableExtraHeader: false, pageAccess: 'All' }
      },
      {
        path: 'edit/:_id',
        component: CreateCertificateComponent,
        canActivate: [AuthGuardService],
        data: { title: 'Edit Pet Certificate', enableExtraHeader: false, pageAccess: 'All' }
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PetCertificatesRoutingModule {}
