<span class="certificateTitle">Microchip Certificate</span>
<div class="mainContents">
  <p class="contents">
    This certifies that the Pet named <span class="cert-elements">{{ certificateDetails?.pet.name }} </span> (referred
    to as "Pet"), a <em>{{ certificateDetails?.pet.sex }}</em>
    <span class="cert-elements"> {{ certificateDetails?.pet.species }}</span> of the
    <em> {{ certificateDetails?.pet.breed.name }}</em> breed,
    <span *ngIf="certificateDetails?.pet?.dob">
      aged <span class="cert-elements">{{ certificateDetails?.pet?.doem | age: 'DD/MM/YYYY' }}</span> ,
    </span>
    identified by
    <span class="cert-elements"
      >Pet ID: <em> {{ certificateDetails?.pet.petid }}</em></span
    >, owned by
    <span class="cert-elements"
      >{{ certificateDetails?.customer.first_name }} {{ certificateDetails?.customer.last_name }} </span
    >,
    <span *ngIf="certificateDetails?.address">
      residing at <em> {{ certificateDetails?.address }}</em> </span
    >, has been implanted with a microchip. <br />
    <br />
    The Microchip Number assigned to the Pet is <strong> {{ certificateDetails?.microchipNo }}</strong
    >.
  </p>
</div>
