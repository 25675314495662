import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthName'
})
export class MonthNamePipe implements PipeTransform {
  private monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  transform(month: any): string {
    const monthNumber = Number(month);
    if (monthNumber < 1 || monthNumber > 12) { return ''; } // Handle invalid month input
    return this.monthNames[monthNumber - 1];
  }
}
