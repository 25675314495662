import { Component } from '@angular/core';
import { CommonService } from '../common-service.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  navLarge = false;
  isLoggedIn = true;

  constructor(private commonService: CommonService) {}

  getCurrentNav(val: boolean) {
    // this.navLarge = val;
  }
}
