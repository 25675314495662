<span class="certificateTitle">Pet Travel Certificate</span>
<div class="mainContents">
  <p class="contents">
    This document certifies that the pet named
    <span class="cert-elements">{{ certificateDetails?.pet.name }} </span> (referred to as "Pet"), a
    <em>{{ certificateDetails?.pet.sex }}</em>
    <span class="cert-elements"> {{ certificateDetails?.pet.species }}</span> of
    <em> {{ certificateDetails?.pet.breed.name }}</em> breed,
    <span *ngIf="certificateDetails?.pet?.dob">
      aged <span class="cert-elements">{{ certificateDetails?.pet?.dob | age: 'DD/MM/YYYY' }}</span
      >,
    </span>
    identified by
    <span *ngIf="certificateDetails?.microchipNo">
      <span class="cert-elements">
        Microchip No: <em>{{ certificateDetails?.microchipNo }} </em>
      </span>
      and
    </span>
    <span class="cert-elements"
      >Pet ID: <em>{{ certificateDetails?.pet.petid }}</em></span
    >, owned by
    <span class="cert-elements"
      >{{ certificateDetails?.customer.first_name }} {{ certificateDetails?.customer.last_name }} </span
    >, residing at <em>{{ certificateDetails?.address }}</em
    >, has been examined by a licensed veterinarian. <br /><br />

    To the best of the veterinarian's knowledge,
    <span class="cert-elements">
      <em>{{ certificateDetails?.pet.name }}</em>
    </span>
    appears to be free from any visible signs of contagious diseases or external parasites. Additionally, the pet has
    been vaccinated and is up to date with all required vaccinations from
    <span class="cert-elements">
      <em>{{ certificateDetails?.fromMonth | monthName }}, {{ certificateDetails?.fromYear }}</em>
    </span>
    to
    <span class="cert-elements">
      <em>{{ certificateDetails?.toMonth | monthName }}, {{ certificateDetails?.toYear }}</em> </span
    >. <br /><br />

    Based on the physical examination, the pet is deemed <span class="cert-elements">fit to travel </span> from
    <span class="cert-elements">{{ certificateDetails?.fromLocation }} </span> to
    <span class="cert-elements">{{ certificateDetails?.toLocation }} </span> .
  </p>
</div>
