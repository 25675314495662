export class Clinic {
  _id: string;
  name: string;
  status: string;
  address: string;
  phone_no: string;
  clinic_gst: string;
  store_gst: string;
  logo_url?: string;
}
