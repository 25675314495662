import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  transform(value: any, formatType?: any): any {
    if (value) {
      const today = moment();

      const dateMomentObject = moment(value, formatType); // 1st argument - string, 2nd argument - format
      const dob = dateMomentObject.toDate();
      const dobYear = today.diff(dob, 'years');
      const dobMnth = today.diff(dob, 'months') % 12;

      return dobYear + '.' + dobMnth + ' yr';
    } else {
      return '-';
    }
  }
}
