<div class="col-md-12 pageTitle">
  <label class="title pt-4 pl-4" *ngIf="certificateId">Edit Certificate</label>
  <label class="title pt-4 pl-4" *ngIf="!certificateId">Create Certificate</label>
</div>
<div class="container">
  <div class="col-md-12">
    <div class="mt-2 mb-4 container-items">
      <div class="col-lg-10 col-md-10 mt-3 text-center certificate-item">
        <label for="category" class="certificate-label col-md-4 required">Certificate Type:</label>
        <select
          name="certificateType"
          id="category"
          [disabled]="certificateId"
          class="form-control input-font type inputBox col-md-6"
          [(ngModel)]="certificateType"
        >
          <option value="" disabled selected>Select Certificate Type</option>
          <option value="HEALTH_CERTIFICATE">Health certificate</option>
          <option value="SURGERY_CERTIFICATE">Surgery Certificate</option>
          <option value="VACCINATION_CERTIFICATE">Vaccination Certificate</option>
          <option value="SPAYED_NEUTER_SURGERY_PROCEDURE_CERTIFICATE">
            Spayed Neuter Surgery Procedure Certificate
          </option>
          <option value="TRAVEL_CERTIFICATE">Travel Certificate</option>
          <option value="MICROCHIP_CERTIFICATE">Microchip Certficate</option>
          <option value="EUTHANASIA_CONSENT_FORM">Euthenesia Consent Form</option>
        </select>
      </div>
      <div class="col-lg-10 col-md-10 mt-2 text-center" *ngIf="certificateType">
        <label for="petName" class="certificate-label col-md-4 required">Pet Name:</label>
        <mat-form-field class="search-field col-md-6 px-0" *ngIf="!certificateId">
          <input
            matInput
            type="text"
            class="search form-control input-font inputBox col-md-6"
            #searchBox
            (keyup)="search$.next($event.target.value)"
            maxlength="50"
            placeholder="Type to Search Pet by Pet Name / Owner Number"
            inputText
            allowType="TEXTDIGITS"
            id="petName"
            [readonly]="certificateId || this.selectedPet"
            [(ngModel)]="certificateDetails.petName"
          />
          <button
            *ngIf="certificateDetails.petName"
            mat-icon-button
            matSuffix
            aria-label="Clear"
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <input
          *ngIf="certificateId"
          type="text"
          class="search form-control input-font inputBox col-md-6"
          #searchBox
          maxlength="50"
          placeholder="Type to Search Pet by Pet Name / Owner Number"
          inputText
          allowType="TEXTDIGITS"
          id="petName"
          disabled
          [(ngModel)]="certificateDetails.petName"
        />
        <div class="col-lg-6 col-md-6 text-center search-results-container" *ngIf="petArray.length > 0">
          <div *ngFor="let pet of petArray" class="pet-item search-result">
            <div
              class="pet-link searchedPets cursor-pointer text-primary"
              title="Allows you to view/add/modify pet health details"
              (click)="selectPet(pet)"
            >
              {{ pet?.name }} ( {{ pet?.petid }} ) {{ pet?.customer?.first_name }} {{ pet?.customer?.last_name }} -
              {{ pet?.breed?.name }} -
              {{ pet?.customer?.phone_no }}
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="(certificateType && selectedPet) || certificateId">
        <div class="col-lg-10 col-md-10 text-center" [ngClass]="{ 'mt-2': certificateId }">
          <label for="species" class="certificate-label col-md-4 required">Pet Species:</label>
          <input
            type="text"
            inputText
            allowType="ONLYTEXTS"
            class="form-control input-font inputBox col-md-6"
            id="species"
            disabled
            maxlength="50"
            placeholder="Enter Pet Breed"
            [(ngModel)]="certificateDetails.petspecies"
          />
        </div>
        <div class="col-lg-10 col-md-10 mt-2 text-center">
          <label for="breed" class="certificate-label col-md-4 required">Pet Breed:</label>
          <input
            type="text"
            inputText
            allowType="ONLYTEXTS"
            class="form-control input-font inputBox col-md-6"
            id="breed"
            disabled
            maxlength="50"
            placeholder="Enter Pet Breed"
            [(ngModel)]="certificateDetails.petbreed"
          />
        </div>
        <div class="col-lg-10 col-md-10 mt-2 text-center">
          <label for="ownerName" class="certificate-label col-md-4 required">Owner's Name:</label>
          <input
            type="text"
            inputText
            allowType="ONLYTEXTS"
            class="form-control input-font inputBox col-md-6"
            id="ownerFirstName"
            disabled
            maxlength="50"
            placeholder="Owner's Name"
            [(ngModel)]="certificateDetails.name"
          />
        </div>
        <div class="col-lg-10 col-md-10 mt-2 text-center">
          <label for="ownerContact" class="certificate-label col-md-4 required">Owner's Contact No.:</label>
          <input
            type="text"
            inputText
            allowType="ONLYDIGITS"
            class="form-control input-font inputBox col-md-6"
            id="ownerContact"
            maxlength="10"
            placeholder="Enter Owner's Phone Number"
            disabled
            [(ngModel)]="certificateDetails.phone"
          />
        </div>
        <div class="col-lg-10 col-md-10 mt-2 text-center">
          <label for="ownerContact" class="certificate-label col-md-4">Owner's Address:</label>
          <input
            type="text"
            inputText
            class="form-control input-font inputBox col-md-6"
            id="ownerAddress"
            maxlength="150"
            placeholder="Enter Owner's Complete Address"
            [(ngModel)]="certificateDetails.address"
          />
        </div>
        <div class="col-lg-10 col-md-10 mt-2 text-center">
          <label for="microchipNo" class="certificate-label col-md-4">Microchip Number:</label>
          <input
            type="text"
            inputText
            allowType="TEXTDIGITS"
            class="form-control input-font inputBox col-md-6"
            id="microchipNo"
            maxlength="30"
            placeholder="Enter Microchip Number"
            [(ngModel)]="certificateDetails.microchipNo"
          />
        </div>
        <ng-container *ngIf="certificateType === 'SURGERY_CERTIFICATE'">
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="medicalCondition" class="certificate-label col-md-4">Medical Condition (if any):</label>
            <input
              type="text"
              inputText
              allowType="TEXTDIGITS"
              class="form-control input-font inputBox col-md-6"
              id="vaccineName"
              [(ngModel)]="certificateDetails.medicalCondition"
            />
          </div>
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="operatedFor" class="certificate-label col-md-4">Operated For (if any):</label>
            <input
              type="text"
              inputText
              allowType="TEXTDIGITS"
              class="form-control input-font inputBox col-md-6"
              id="spayedFor"
              [(ngModel)]="certificateDetails.surgicalProcedure"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="certificateType === 'VACCINATION_CERTIFICATE'">
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="vaccineName" class="certificate-label col-md-4 required">Vaccine Name:</label>
            <input
              type="text"
              inputText
              allowType="TEXTDIGITS"
              class="form-control input-font inputBox col-md-6"
              id="vaccineName"
              [(ngModel)]="certificateDetails.vaccineName"
            />
          </div>
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="fromDate" class="certificate-label col-md-4 required">From Date:</label>
            <select
              [(ngModel)]="certificateDetails.fromMonth"
              class="form-control input-font type inputBox col-md-3"
              name="fromMonth"
              (change)="adjustFromDate()"
            >
              <option *ngFor="let month of months" [value]="month.value">{{ month.label }}</option>
            </select>
            <select
              [(ngModel)]="certificateDetails.fromYear"
              name="fromYear"
              class="form-control input-font type inputBox col-md-3"
              (change)="adjustFromDate()"
            >
              <option *ngFor="let year of fromYears" [value]="year">{{ year }}</option>
            </select>
          </div>
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="toDate" class="certificate-label col-md-4 required">To Date:</label>
            <select
              [(ngModel)]="certificateDetails.toMonth"
              name="toMonth"
              class="form-control input-font type inputBox col-md-3"
              (change)="adjustToDate()"
            >
              <option *ngFor="let month of months" [value]="month.value">{{ month.label }}</option>
            </select>
            <select
              name="toYear"
              class="form-control input-font type inputBox col-md-3"
              [(ngModel)]="certificateDetails.toYear"
              (change)="adjustToDate()"
            >
              <option *ngFor="let year of toYears" [value]="year">{{ year }}</option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="certificateType === 'SPAYED_NEUTER_SURGERY_PROCEDURE_CERTIFICATE'">
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="operatedFor" class="certificate-label col-md-4 required">Operated For:</label>
            <input
              type="text"
              inputText
              allowType="TEXTDIGITS"
              class="form-control input-font inputBox col-md-6"
              id="spayedFor"
              [(ngModel)]="certificateDetails.surgicalProcedure"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="certificateType === 'TRAVEL_CERTIFICATE'">
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="fromDate" class="certificate-label col-md-4 required">Vaccination From Date:</label>
            <select
              [(ngModel)]="certificateDetails.fromMonth"
              class="form-control input-font type inputBox col-md-3"
              name="certificateDetails.fromMonth"
              (change)="adjustFromDate()"
            >
              <option *ngFor="let month of months" [value]="month.value">{{ month.label }}</option>
            </select>
            <select
              [(ngModel)]="certificateDetails.fromYear"
              name="certificateDetails.fromYear"
              class="form-control input-font type inputBox col-md-3"
              (change)="adjustFromDate()"
            >
              <option *ngFor="let year of fromYears" [value]="year">{{ year }}</option>
            </select>
          </div>
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="toDate" class="certificate-label col-md-4 required">Vaccination To Date:</label>
            <select
              [(ngModel)]="certificateDetails.toMonth"
              name="certificateDetails.toMonth"
              class="form-control input-font type inputBox col-md-3"
              (change)="adjustToDate()"
            >
              <option *ngFor="let month of months" [value]="month.value">{{ month.label }}</option>
            </select>
            <select
              name="toYear"
              class="form-control input-font type inputBox col-md-3"
              [(ngModel)]="certificateDetails.toYear"
              (change)="adjustToDate()"
            >
              <option *ngFor="let year of toYears" [value]="year">{{ year }}</option>
            </select>
          </div>
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="travelFrom" class="certificate-label col-md-4 required">Travelling From:</label>
            <input
              type="text"
              inputText
              allowType="TEXTDIGITS"
              class="form-control input-font inputBox col-md-6"
              id="travelFrom"
              maxlength="50"
              [(ngModel)]="certificateDetails.fromLocation"
            />
          </div>
          <div class="col-lg-10 col-md-10 mt-2 text-center">
            <label for="travelTo" class="certificate-label col-md-4 required"> Travelling To:</label>
            <input
              type="text"
              inputText
              allowType="TEXTDIGITS"
              class="form-control input-font inputBox col-md-6"
              id="travelTo"
              maxlength="50"
              [(ngModel)]="certificateDetails.toLocation"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="(certificateType && selectedPet) || certificateId">
          <div class="col-lg-10 col-md-10 mt-3 text-center d-flex">
            <label for="" class="col-md-4"></label>
            <div class="col-md-6 text-center">
              <input type="checkbox" id="forDigitalSignature" [(ngModel)]="certificateDetails.addSignature" />
              <label for="forDigitalSignature" class="text-info cursor-pointer"
                >&nbsp;&nbsp; Add Vetrinarian's Digital Signaure
              </label>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 mt-2 text-center">
    <button
      class="btn blueButtonAdd fa fa-times"
      routerLinkActive="router-link-active"
      [routerLink]="['/certificate', 'list']"
      title=" Go back to Certificate List"
    >
      &nbsp; Cancel
    </button>
    <button
      class="btn blueButtonAdd fa fa-plus"
      *ngIf="certificateId"
      (click)="updateCertificate()"
      title="Update Certificate"
    >
      &nbsp; Update
    </button>
    <button
      class="btn blueButtonAdd fa fa-plus"
      *ngIf="!certificateId"
      (click)="createNewCertificate()"
      title="Create Certificate"
    >
      &nbsp; Create
    </button>
  </div>
</div>
