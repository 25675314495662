<div class="invoice-container">
  <button class="mb-3 oval btn payBlue downloadButton fa-download fa" (click)="actionButton('download')">
    &nbsp;&nbsp;Download
  </button>
  <button class="mb-3 oval btn payBlue printButton fa fa-print" (click)="actionButton('print')">
    &nbsp;&nbsp;Print
  </button>

  <div class="custom-checkbox">
    <input id="status" type="checkbox" name="status" (click)="toggleTaxColumn()" class="toggle-button" />
    <label for="status">
      <div class="status-switch" data-checked="Hide" data-unchecked="Show"></div>
      <span> {{ showTax ? 'Tax details on print' : 'Tax details on print' }}</span>
    </label>
  </div>

  <div class="main">
    <div class="invoiceMainDiv" id="invoice" #invoice>
      <img
        alt="Logo"
        [src]="mainData?.clinic?.logo_url"
        class="invoice-image"
        *ngIf="mainData.clinic && mainData.clinic.logo_url"
      />
      <span class="clinic-name">{{ mainData?.clinic?.name }}</span>
      <div class="clinic-group address">
        <div>
          <span class="address header-text">{{ mainData?.clinic?.address }}</span>
        </div>

        <div class="gst-licence">
          <div class="gst-line-item">
            <span class="mobile header-icon font-weight-bold">Ph: </span>
            <span class="mobile header-text">{{ mainData?.clinic?.phone_no }}</span>
            <span class="mobile header-text" *ngIf="mainData?.clinic?.phone_no2"
              >/ {{ mainData?.clinic?.phone_no2 }}</span
            >
          </div>
          <div class="gst-line-item" *ngIf="mainData?.clinic?.website">
            <span class="website header-icon font-weight-bold"> ||&nbsp;&nbsp;&nbsp;&nbsp;W: </span>
            <span class="website header-text"> {{ mainData?.clinic?.website }}</span>
          </div>
          <div class="gst-line-item" *ngIf="mainData?.clinic?.email_id">
            <span class="email header-icon font-weight-bold"> ||&nbsp;&nbsp;&nbsp;&nbsp;E: </span>
            <span class="email header-text"> {{ mainData?.clinic?.email_id }}</span>
          </div>
        </div>
        <div class="gst-licence">
          <div class="gst-line-item" *ngIf="mainData.clinic && mainData.clinic.clinic_gst && showTax">
            <span class="gst header-icon font-weight-bold">GST: </span>
            <span class="gst header-text"> {{ mainData?.clinic?.clinic_gst }}</span>
          </div>
          <div class="gst-line-item" *ngIf="mainData.clinic && mainData.clinic.license_number">
            <span *ngIf="mainData.clinic.clinic_gst && showTax">||&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="license header-icon font-weight-bold"> License: </span>
            <span class="license header-text"> {{ mainData?.clinic?.license_number }} </span>
          </div>
        </div>
      </div>
      <div class="pet-details-section row">
        <div class="pet-group first col-sm">
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Pet Name: </span>
            <span class="pet-text">{{ mainData?.pet?.name }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Owner Name: </span>
            <span class="pet-text">{{ mainData?.customer?.first_name }} {{ mainData?.customer?.last_name }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Prescribed By: </span>
            <span class="pet-text">
              {{ mainData?.prescribed_by?.salutation }} {{ mainData?.prescribed_by?.first_name }}
              {{ mainData?.prescribed_by?.last_name }}</span
            >
          </div>
          <div
            class="pet-section"
            *ngIf="mainData.checkup && mainData.checkup.department && mainData.checkup.department === 'IPD'"
          >
            <span class="pet-text font-weight-bold">Check In Date: </span>
            <span class="pet-text">{{ mainData?.checkup?.check_in_date | date: 'dd-MM-yyyy' }}</span>
          </div>
        </div>
        <div class="pet-group second col-sm">
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Pet ID: </span>
            <span class="pet-text">{{ mainData?.pet?.pet_id }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Owner Phone No: </span>
            <span class="pet-text">{{ mainData?.customer?.phone_no }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Department: </span>
            <span class="pet-text">{{ mainData?.checkup?.department }}</span>
          </div>
        </div>
        <div class="pet-group third col-sm">
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Date: </span>
            <span class="pet-text">{{ mainData?.date | date: 'dd-MM-yyyy' }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Bill No: </span>
            <span class="pet-text">{{ mainData?.invoiceid }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Channel: </span>
            <span class="pet-text">{{ mainData?.checkup?.channel }}</span>
          </div>
          <div
            class="pet-section"
            *ngIf="mainData.checkup && mainData.checkup.department && mainData.checkup.department === 'IPD'"
          >
            <span class="pet-text font-weight-bold">Check Out Date: </span>
            <span class="pet-text">{{ mainData?.checkup?.check_out_date | date: 'dd-MM-yyyy' }}</span>
          </div>
        </div>
      </div>

      <div class="bill-group" id="mainBillDetails">
        <div class="bill-group-header row m-0">
          <span class="bill-group-text header col-2" [ngClass]="showTax ? 'col-2' : 'col-3'">&nbsp;&nbsp;Name</span>
          <span class="bill-group-text header col-2" [ngClass]="showTax ? 'col-2' : 'col-3'">Category</span>
          <span class="bill-group-text header col-1">Qty</span>
          <span class="bill-group-text header col-1" *ngIf="showTax">Tax %</span>
          <span class="bill-group-text header col-1" *ngIf="showTax">HSN</span>
          <span class="bill-group-text header col-1">Expiry</span>
          <span class="bill-group-text header col-1">MRP</span>
          <span class="bill-group-text header col-1">Discount</span>
          <span class="bill-group-text header col-1">Price</span>
          <span class="bill-group-text header col-1">Total</span>
        </div>
        <div class="bill-group-body row" *ngFor="let item of this.rowData; let index = index">
          <span class="bill-group-text body col-2" [ngClass]="showTax ? 'col-2' : 'col-3'"
            >{{ index + 1 }}.&nbsp;&nbsp;{{ item?.name.toLowerCase() }}</span
          >
          <span
            class="bill-group-text body col-2"
            *ngIf="!item.category"
            [ngClass]="showTax ? 'col-2' : 'col-3'"
          ></span>
          <span
            class="bill-group-text body col-2"
            *ngIf="item.category && item.category.length > 1"
            [ngClass]="showTax ? 'col-2' : 'col-3'"
            >{{ item?.category.toLowerCase() }}</span
          >
          <span class="bill-group-text body col-1">{{ item?.qty }}</span>
          <span class="bill-group-text body col-1" *ngIf="showTax">{{ item?.tax }}</span>
          <span class="bill-group-text body col-1" *ngIf="showTax">{{ item?.hsn?.code }}</span>
          <span class="bill-group-text body col-1">{{ item?.expiry_date | date: 'MM-yy' }}</span>
          <span class="bill-group-text body col-1">{{
            item?.original_unit_price | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
          }}</span>
          <span class="bill-group-text body col-1">{{
            item?.discount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
          }}</span>
          <span class="bill-group-text body col-1">{{
            item?.price | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
          }}</span>
          <span class="bill-group-text body col-1">{{
            item?.total_price | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
          }}</span>
        </div>
        <div class="bill-group-footer row mb-4">
          <div class="pet-section col-4 offset-md-8 row pt-4">
            <span class="pet-text font-weight-bold col-7">Sub-Total</span>
            <span class="pet-text col-5">{{ subTotal | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</span>
          </div>
          <div class="pet-section col-4 offset-md-8 row pt-2" *ngIf="mainData?.discount > 0">
            <span class="pet-text font-weight-bold col-7">Addn Discount</span>
            <span class="pet-text col-5">{{
              mainData?.discount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
            }}</span>
          </div>

          <ng-template ngFor let-tItem [ngForOf]="taxData" let-index="index">
            <div class="pet-section col-4 offset-md-8 row pt-2 tax" *ngIf="tItem.tax_slab > 0 && showTax">
              <span class="pet-text font-weight-bold col-7"> SGST ({{ tItem?.tax_slab / 2 }}%) </span>
              <span class="pet-text col-5">
                {{ tItem?.amount / 2 | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}
              </span>
            </div>
            <div class="pet-section col-4 offset-md-8 row pt-2 tax" *ngIf="tItem.tax_slab > 0 && showTax">
              <span class="pet-text font-weight-bold col-7"> CGST ({{ tItem?.tax_slab / 2 }}%) </span>
              <span class="pet-text col-5">{{
                tItem?.amount / 2 | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
              }}</span>
            </div>
          </ng-template>
          <div class="pet-section col-4 offset-md-8 row pt-2" *ngIf="showTax">
            <span class="pet-text font-weight-bold col-7">Tax</span>
            <span class="pet-text col-5">{{ tax_amount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</span>
          </div>
          <div
            class="pet-section col-4 offset-md-8 row pt-2"
            *ngIf="mainData.checkup && mainData.checkup.advance_paid && mainData.checkup.advance_paid > 0"
          >
            <span class="pet-text font-weight-bold col-7">Advance Paid</span>
            <span class="pet-text col-5">{{
              mainData?.checkup?.advance_paid | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
            }}</span>
          </div>
          <div class="pet-section col-4 offset-md-8 row pt-2">
            <span class="pet-text font-weight-bold col-7">Total Billed Amount</span>
            <span class="pet-text col-5">{{
              mainData?.amount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'
            }}</span>
          </div>
        </div>
        <div class="bill-group-text pl-3 pt-2">
          <h6>
            Completed By: {{ mainData?.completed_by?.salutation }}
            {{ mainData?.completed_by?.first_name }}
            {{ mainData?.completed_by?.last_name }}
          </h6>
        </div>
        <div class="main-footer">
          <div class="d-flex justify-content-center pawsnme-text">Powered by PawsNme</div>
          <span class="d-flex justify-content-center"> This is Computer Generated Invoice, No Signature Required </span>
        </div>
      </div>
    </div>
  </div>
</div>
