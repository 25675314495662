import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[selectText]'
})
export class SelectTextDirective {
  constructor(private el: ElementRef) {}

  @HostListener('focus', ['$event']) onFocus(event: FocusEvent) {
    setTimeout(() => {
      this.el.nativeElement.select();
    }, 0);
  }
}
