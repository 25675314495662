import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-register-customer-dialog',
  templateUrl: './register-customer-dialog.component.html',
  styleUrls: ['./register-customer-dialog.component.scss']
})
export class RegisterCustomerDialogComponent implements OnInit {
  public fName: string;
  public lName: string;
  public email: string;
  public phone: string;
  public store_id: string;
  disableConfirm = true;

  constructor(public dialogRef: MatDialogRef<RegisterCustomerDialogComponent>) {}

  ngOnInit(): void {
    this.disableConfirm = true;
  }

  changeValue() {
    if (this.phone.length === 10 && this.fName && this.fName.length > 0) {
      this.disableConfirm = false;
    } else {
      this.disableConfirm = true;
    }
  }

  confirmCustomer() {
    let cust_details;
    if (this.store_id) {
      cust_details = {
        firstname: this.fName,
        lastname: this.lName,
        email: this.email,
        phonenumber: this.phone,
        PO: this.store_id
      };
    } else {
      cust_details = {
        firstname: this.fName,
        lastname: this.lName,
        email: this.email,
        phonenumber: this.phone
      };
    }
    this.dialogRef.close(cust_details);
  }
}
