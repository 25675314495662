<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">{{ headerTitle }}</h1>
<mat-dialog-content class="mb-4">
  <div class="mt-3 wid">
    <div class="col-md-12 mb-2">
      <label for="category" class="toPay col-md-2 required">Category</label>
      <select
        name="category"
        id="category"
        class="form-control input-font category inputBox col-md-4"
        [(ngModel)]="category"
        (change)="categoryChanged(true)"
      >
        <option value="CAN-FOOD">Can-Food</option>
        <option value="CAPSULE">Capsule</option>
        <option value="DISPOSABLE">Disposables</option>
        <option value="DROPS">Drops</option>
        <option value="DRY-SYRUP">Dry-Syrup</option>
        <option value="EAR-DROPS">Ear-Drops</option>
        <option value="EYE-DROPS">Eye-Drops</option>
        <option value="GEL">Gel</option>
        <option value="INJECTABLE">Injectables</option>
        <option value="LOTION">Lotion</option>
        <option value="OINTMENT">Ointment</option>
        <option value="OINTMENT-SPRAY">Ointment & Spray</option>
        <option value="ORAL-DROPS">Oral-Drops</option>
        <option value="ORAL-SPRAY">Oral-Spray</option>
        <option value="ORAL-SUPPLEMENTARY">Oral-Supplementary</option>
        <option value="POWDER-SHAMPOO">Shampoo & Powder</option>
        <option value="PRODUCT">Product</option>
        <option value="SERVICES">Services</option>
        <option value="SOFTGEL-CAPSULE">Softgel-Capsule</option>
        <option value="SPOT-ON">Spot-On</option>
        <option value="SUPPOSITORY">Suppository</option>
        <option value="SYRUP">Syrup</option>
        <option value="SYRUP-SUPPLEMENT">Syrup & Supplement</option>
        <option value="TABLET">Tablet</option>
        <option value="TOPICAL-POWDER">Topical-Powder</option>
        <option value="TOPICAL-SPRAY">Topical-Spray</option>
        <option value="VACCINATION-DEWORMING">Vaccination & Deworming</option>
      </select>
      <label for="name" class="payingClinic col-md-2 required">Name</label>
      <ng-select
        [items]="nameData"
        bindLabel="name"
        [addTag]="addName"
        [multiple]="false"
        placeholder="Select Item"
        (change)="onNameChange()"
        (clear)="onNameClear($event)"
        class="col-md-4 d-inline-block pl-0 pr-0"
        [(ngModel)]="name_selected"
        *ngIf="category !== 'SERVICES'"
        (keypress)="onKeyPress($event)"
      >
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.name }}">{{ item.name }}</div>
        </ng-template>
      </ng-select>
      <input
        type="text"
        class="form-control input-font inputBox col-md-4"
        id="nameText"
        *ngIf="category == 'SERVICES'"
        inputText
        allowType="TEXTDIGITSPCL"
        [(ngModel)]="nameText"
      />
    </div>
    <div class="col-md-12 mb-2">
      <label for="price" class="col-md-2 toPay required">Selling Price</label>
      <input
        type="text"
        inputText
        allowType="DIGITSWTHDOT"
        class="form-control input-font price inputBox col-md-4"
        id="price"
        [(ngModel)]="price"
        min="0"
      />
      <label for="qty" class="col-md-2 toPay required" title="Quantity" *ngIf="category !== 'SERVICES'">Qty</label>
      <input
        type="text"
        inputText
        allowType="ONLYDIGITS"
        class="col-md-4 form-control input-font qty inputBox"
        id="qty"
        [(ngModel)]="qty"
        min="0"
        maxlength="4"
        *ngIf="category !== 'SERVICES'"
      />
      <!-- <div class="col-md-2 dates">
        <label for="mrp" class="toPay">MRP</label>
      </div>
      <div class="col-md-4 dates payDate">
        <input
          type="text"
          inputText
          allowType="DIGITSWTHDOT"
          class="form-control input-font price inputBox"
          id="mrp"
          [(ngModel)]="mrp"
          min="0"
        />
      </div> -->
    </div>
    <div class="col-md-12 mb-2">
      <label for="buyPrice" class="toPay col-md-2" *ngIf="category !== 'SERVICES'">Buying Price</label>
      <input
        type="text"
        inputText
        allowType="DIGITSWTHDOT"
        class="form-control input-font price inputBox col-md-2"
        id="buyPrice"
        [(ngModel)]="buyPrice"
        min="0"
        *ngIf="category !== 'SERVICES'"
      />
      <label for="tax" class="toPay col-md-1" title="Tax %" *ngIf="category !== 'SERVICES'">Tax(%)</label>
      <select
        name="tax"
        id="tax"
        class="form-control input-font price inputBox col-md-1 p-0"
        title="Tax %"
        [(ngModel)]="tax"
        *ngIf="category !== 'SERVICES'"
      >
        <option value="0" default>0</option>
        <option value="5">5</option>
        <option value="12">12</option>
        <option value="18">18</option>
        <option value="28">28</option>
      </select>
      <label for="hsn_selected" class="payingClinic col-md-2">HSN</label>
      <ng-select
        [items]="hsnList"
        bindLabel="code"
        [multiple]="false"
        placeholder="Select HSN"
        class="col-md-4 d-inline-block pl-0 pr-0"
        [(ngModel)]="hsn_selected"
        (keypress)="onKeyPress($event)"
        [disabled]="this.name_selected._id"
      >
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.code }}">{{ item.code }}</div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-md-12 mb-2" *ngIf="category !== 'SERVICES'">
      <label for="batch_name" class="toPay col-md-2">Batch</label>
      <input
        type="text"
        class="form-control input-font price inputBox col-md-4"
        id="batch_name"
        inputText
        allowType="TEXTDIGITSPCL"
        [(ngModel)]="batch_name"
        min="0"
        [disabled]="this.name_selected._id"
      />
      <div class="col-md-2 dates">
        <label for="expiry_date" class="toPay">Exp Date</label>
      </div>
      <div class="col-md-4 dates payDate">
        <my-date-picker
          name="nextVaccination"
          [options]="myDatePickerOptionsExp"
          class="datePicker"
          id="expiry_date"
          (dateChanged)="onNextDateChanged($event)"
        >
        </my-date-picker>
      </div>
    </div>
    <div class="col-md-12 mb-2" *ngIf="category !== 'SERVICES'">
      <label for="manufacturer" class="toPay col-md-2">Manufacturer</label>
      <input
        type="text"
        class="form-control input-font price inputBox col-md-4"
        id="manufacturer"
        inputText
        allowType="TEXTDIGITSPCL"
        [(ngModel)]="manufacturer"
        maxlength="30"
        [disabled]="this.name_selected._id"
      />
      <label for="seller_name" class="toPay col-md-2">Seller Name</label>
      <input
        type="text"
        class="form-control input-font price inputBox col-md-4"
        id="seller_name"
        inputText
        allowType="TEXTDIGITSPCL"
        [(ngModel)]="seller_name"
        maxlength="30"
        [disabled]="this.name_selected._id"
      />
    </div>
    <div class="col-md-12 mb-2" *ngIf="category !== 'SERVICES'">
      <label for="instructions" class="toPay col-md-2">Instructions</label>
      <input
        type="text"
        class="form-control input-font price inputBox col-md-4"
        id="instructions"
        inputText
        allowType="TEXTDIGITSPCL"
        [(ngModel)]="instructions"
        maxlength="30"
        [disabled]="this.name_selected._id"
      />
      <label for="rack_no" class="toPay col-md-2">Rack No</label>
      <input
        type="text"
        class="form-control input-font qty inputBox col-md-4"
        id="rack_no"
        [(ngModel)]="rack_no"
        inputText
        allowType="TEXTDIGITSPCL"
        maxlength="15"
        [disabled]="this.name_selected._id"
      />
    </div>

    <div class="col-md-12 mb-2" *ngIf="category !== 'SERVICES'">
      <label for="unit" class="toPay col-md-2 required">Unit</label>
      <ng-select
        [items]="unitData"
        bindLabel="unit"
        [addTag]="true"
        [multiple]="false"
        (change)="onUnitChange($event)"
        (clear)="onUnitClear($event)"
        class="col-md-4 d-inline-block pl-0 pr-0"
        [(ngModel)]="unit"
        (keypress)="onKeyPress($event)"
      >
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item }}">{{ item }}</div>
        </ng-template>
      </ng-select>

      <label for="rack_no" class="toPay col-md-2">Barcodes</label>
      <mat-form-field class="example-chip-list col-md-4">
        <mat-chip-list #chipList aria-label="Barcode">
          <mat-chip
            *ngFor="let item of barcodes"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(item)"
          >
            {{ item }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder=""
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
            inputText
            allowType="TEXTDIGITS"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="mat-raised-button mr-4 btn blankTurquoise btnWidth"
      (click)="dialogRef.close(false)"
      title="Click to cancel the addition of stock to the inventory"
    >
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth btn-sm updateButton"
      (click)="createItem()"
      title="Click to confirm addition of stock to the inventory"
    >
      Add
    </button>
    <!-- <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth btn-sm updateButton"
      (click)="createBatchItem()"
      *ngIf="batchFlag"
    >
      Create Batch
    </button> -->
  </div>
</mat-dialog-actions>
