<!-- <div class="sidebar__header">
  <a id="sidebar-toggle navbar " class="sidebar-toggle hidden-xs hidden-sm">
    <a href="javascript:void(0)" class="navbar-toggler" aria-expanded="false" aria-label="Toggle navigation"
      (click)="toggleNav()">
      <span class="fa fa-navicon" [ngClass]="{'fa-remove': navLarge,'fa-navicon':!navLarge }"></span>
    </a>
  </a>
  <div class="sidebar__header-title"></div>
</div> -->
<section class="mobile-navigation">
  <a href="javascript:;" class="mobile-nav-link" onclick="hamburger(this)">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </a>
</section>
<div class="nav-container">
  <ul id="rootsidebar" [ngClass]="{ fixed: headerFixed }">
    <li *ngFor="let item of navList; let i = index">
      <a
        href="javascript:void(0)"
        (click)="item.open = !item.open"
        [routerLink]="item.url"
        [routerLinkActive]="['active']"
      >
        <!-- <img src="{{item.imgPath}}" class="nav-icon" alt="" /> -->
        <mat-icon class="nav-icon">{{ item.icon }}</mat-icon>
        <!-- <span [ngClass]="item.icon" class="nav-icon"> </span> -->
        <span *ngIf="item.profileID" class="text-capitalize"> {{ item?.profileID }} </span>
        <span *ngIf="!item.profileID"> {{ item?.name }} </span>
      </a>
      <a href="javascript:;" class="arrow" (click)="item.open = !item.open">
        <span class="fa fa-chevron-down"></span>
      </a>
      <!-- <span *ngIf="navLarge && item.links.length" class="arrow fa" [ngClass]="{'fa-chevron-up': item.open , 'fa-chevron-down': !item.open }"></span> -->
      <ul class="side_bar_child" [ngClass]="{ open: item.open }">
        <li *ngFor="let child of item.links">
          <a [routerLink]="child.url" [routerLinkActive]="['active']" class="{{ item?.class }}"> {{ child?.name }} </a>
        </li>
      </ul>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="logout()">
        <mat-icon class="nav-icon" alt="Logout">logout</mat-icon>Logout
      </a>
    </li>
  </ul>
</div>
