<app-header [isLoggedIn]="isLoggedIn" [navLarge]="navLarge"></app-header>
<div class="container pr-0 pl-0">
  <main class="row">
    <!-- <nav class="sidebar" [ngClass]="{'sidebar--mini': !navLarge  , 'sidebar-large':navLarge}"> -->
    <nav class="col-lg-1 col-md-2 sidebar sidebar--mini">
      <app-sidebar (navToggle)="getCurrentNav($event)"></app-sidebar>
    </nav>
    <div class="content pl-0 main-content col-lg-11 col-md-10">
      <router-outlet #route="outlet"></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
</div>
<block-ui></block-ui>
