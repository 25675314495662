import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constants } from 'src/app/shared-module/constants';
import { Doctor, GlobalResponse, Pet } from 'src/app/shared-module/models';
import { DoctorService } from 'src/app/shared-module/services/doctor.service';
import { ICertificateDetails } from '../models/certificate';
import { ICreateCertificateRequest, ICreateCertificateResp } from '../models/create-certificate-req-resp';
import { PetCertificatesServiceService } from '../pet-certificates-service.service';

@Component({
  selector: 'app-create-certificate',
  templateUrl: './create-certificate.component.html',
  styleUrls: ['./create-certificate.component.scss']
})
export class CreateCertificateComponent implements OnInit {
  certificateId: string | null = null;
  certificateType: string;
  certificateDetails: ICertificateDetails = {} as ICertificateDetails;
  toEditDetails: ICreateCertificateResp = {} as ICreateCertificateResp;
  years: number[] = [];
  search$ = new Subject<string>();
  petArray: any = [];
  selectedPet: Pet;
  confirmButton: string;
  doctor: Doctor;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1; // Months are 0-based, so adding 1

  months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];
  fromYears: number[] = [];
  toYears: number[] = [];
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private service: PetCertificatesServiceService,
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.search$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(result => {
      if (result && result.length > 2) {
        this.blockUI.start('Searching...');
        this.doctorService.searchPets(result.trim()).subscribe(
          (res: GlobalResponse) => {
            this.blockUI.stop();
            if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
              if (res.result && res.result.pet && res.result.petsCount > 0) {
                this.petArray = res.result.pet;
              } else {
                this.petArray = [];
                this.selectedPet = undefined;
                this.toastr.warning(Constants.WARNING_NO_PET_PRESENT);
              }
            }
          },
          error => {
            this.blockUI.stop();
            this.toastr.error(error);
          }
        );
      }
    });

    // const currentYear = new Date().getFullYear();
    // this.years = this.generateYearRange(2020, currentYear);
  }

  ngOnInit() {
    this.doctor = JSON.parse(localStorage.getItem('login'));
    this.populateFromYears();
    // Populate 'to' years from current year to next 10 years
    this.populateToYears();
    this.certificateDetails.fromMonth = this.currentMonth.toString();
    this.certificateDetails.fromYear = this.currentYear.toString();
    this.certificateDetails.toMonth = this.currentMonth.toString();
    this.certificateDetails.toYear = this.currentYear.toString();
    this.certificateDetails.addSignature = false;
    // This is to get the certificate ID from the route
    this.route.paramMap.subscribe(params => {
      this.certificateId = params.get('_id');

      // To fetch the certificate details based on the ID
      if (this.certificateId) {
        this.service.getCertificateById(this.certificateId).subscribe(res => {
          this.toEditDetails = res.result; // Pre-fill the form with fetched details

          this.certificateType = res.result.certificateType;

          this.certificateDetails._id = this.certificateId;
          //      Items currently unavailable in database for pet certificate
          this.certificateDetails.certificateType = this.certificateType;
          this.certificateDetails.petName = this.toEditDetails.pet.name;
          this.certificateDetails.petspecies = this.toEditDetails.pet.species;
          this.certificateDetails.petbreed = this.toEditDetails.pet.breed.name;
          // this.certificateDetails.petsex = this.toEditDetails.pet.sex;
          this.certificateDetails.petId = this.toEditDetails.pet.petid;
          this.certificateDetails.name =
            this.toEditDetails.customer.first_name + ' ' + this.toEditDetails.customer.last_name;
          this.certificateDetails.phone = this.toEditDetails.customer.phone_no;
          this.certificateDetails.address = this.toEditDetails.address ? this.toEditDetails.address : '';

          //      Items Available in database for pet certificate
          this.certificateDetails.microchipNo = this.toEditDetails.microchipNo ? this.toEditDetails.microchipNo : '';
          this.certificateDetails.medicalCondition = this.toEditDetails.medicalCondition
            ? this.toEditDetails.medicalCondition
            : '';
          this.certificateDetails.surgicalProcedure = this.toEditDetails.surgicalProcedure
            ? this.toEditDetails.surgicalProcedure
            : '';
          this.certificateDetails.vaccineName = this.toEditDetails.vaccineName ? this.toEditDetails.vaccineName : '';
          this.certificateDetails.fromYear = this.toEditDetails.fromYear ? this.toEditDetails.fromYear : '';
          this.certificateDetails.toYear = this.toEditDetails.toYear ? this.toEditDetails.toYear : '';
          this.certificateDetails.fromLocation = this.toEditDetails.fromLocation ? this.toEditDetails.fromLocation : '';
          this.certificateDetails.toLocation = this.toEditDetails.toLocation ? this.toEditDetails.toLocation : '';
          this.certificateDetails.fromMonth = this.toEditDetails.fromMonth ? this.toEditDetails.fromMonth : '';
          this.certificateDetails.toMonth = this.toEditDetails.toMonth ? this.toEditDetails.toMonth : '';
          this.certificateDetails.addSignature = this.toEditDetails.addSignature
            ? this.toEditDetails.addSignature
            : false;
        });
      } else {
        this.certificateDetails.fromYear = this.currentYear.toString();
        this.certificateDetails.fromMonth = this.currentMonth.toString();
        this.certificateDetails.toYear = this.currentYear.toString();
        this.certificateDetails.toMonth = this.currentMonth.toString();
      }
    });
  }
  populateFromYears(): void {
    const startYear = 2020;
    for (let year = startYear; year <= this.currentYear; year++) {
      this.fromYears.push(year);
    }
  }

  populateToYears(): void {
    for (let year = this.currentYear; year <= this.currentYear + 10; year++) {
      this.toYears.push(year);
    }
  }
  adjustFromDate(): void {
    if (
      Number(this.certificateDetails.fromYear) === this.currentYear &&
      Number(this.certificateDetails.fromMonth) > this.currentMonth
    ) {
      this.certificateDetails.fromMonth = null;
      setTimeout(() => {
        this.certificateDetails.fromMonth = this.currentMonth.toString();
      }, 0);
    }
  }

  adjustToDate(): void {
    if (
      this.certificateDetails.toYear === this.currentYear.toString() &&
      Number(this.certificateDetails.toMonth) < this.currentMonth
    ) {
      this.certificateDetails.toMonth = null;
      setTimeout(() => {
        this.certificateDetails.toMonth = this.currentMonth.toString();
      }, 0);
    }
  }
  clearSearch() {
    this.petArray = [];
    this.certificateDetails.petName = undefined;
    this.certificateDetails.petspecies = undefined;
    this.certificateDetails.petbreed = undefined;
    // this.certificateDetails.petsex = this.selectedPet.sex;
    this.certificateDetails.petId = undefined;
    this.certificateDetails.name = undefined;
    this.certificateDetails.phone = undefined;
    this.selectedPet = undefined;
  }

  // Creating the New Certificate
  selectPet(pet) {
    this.selectedPet = pet;

    this.petArray = [];
    this.certificateDetails.petName = this.selectedPet.name;
    this.certificateDetails.petspecies = this.selectedPet.species;
    this.certificateDetails.petbreed = this.selectedPet.breed.name;
    // this.certificateDetails.petsex = this.selectedPet.sex;
    this.certificateDetails.petId = this.selectedPet.petid;
    this.certificateDetails.name = this.selectedPet.customer.first_name + ' ' + this.selectedPet.customer.last_name;
    this.certificateDetails.phone = this.selectedPet.customer.phone_no;
  }

  createNewCertificate() {
    if (
      this.certificateDetails &&
      this.certificateDetails.petName &&
      this.certificateDetails.name &&
      this.certificateDetails.phone
    ) {
      const certificatePayload: ICreateCertificateRequest = {
        address: this.certificateDetails.address,
        microchipNo: this.certificateDetails.microchipNo,
        certificateType: this.certificateType,
        medicalCondition: this.certificateDetails.medicalCondition,
        surgicalProcedure: this.certificateDetails.surgicalProcedure,
        vaccineName: this.certificateDetails.vaccineName,
        fromYear: this.certificateDetails.fromYear,
        toYear: this.certificateDetails.toYear,
        fromLocation: this.certificateDetails.fromLocation,
        toLocation: this.certificateDetails.toLocation,
        fromMonth: this.certificateDetails.fromMonth,
        toMonth: this.certificateDetails.toMonth,
        petId: this.selectedPet._id,
        customerId: this.selectedPet.customer._id,
        doctorId: this.doctor._id,
        addSignature: this.certificateDetails.addSignature
      };
      this.blockUI.start();
      this.service.createNewCertificate(certificatePayload).subscribe(
        (res: GlobalResponse) => {
          this.blockUI.stop();
          this.toastr.success('Certificate Created Successfully');
          this.router.navigate(['/certificate/list']);
        },
        error => {
          this.blockUI.stop();
          this.toastr.error(error);
        }
      );
    } else {
      this.toastr.error('Pet Name, Owner Name & Owner Mobile Number is Required');
    }
  }

  // Updating the Existing Certificate

  updateCertificate() {
    if (this.certificateId) {
      this.blockUI.start();
      this.service.updateExistingCertificate(this.certificateId, this.certificateDetails).subscribe(
        (res: GlobalResponse) => {
          this.blockUI.stop();
          if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
            this.toastr.success('Certificate Updated Successfully');
            this.router.navigate(['/certificate/list']);
            this.certificateDetails = {} as ICertificateDetails;
          }
        },
        (error: any) => {
          this.blockUI.stop();
          this.toastr.error(error);
        }
      );
    }
    // else {
    // Create a new certificate
    // this.service.createCertificate(this.certificateDetails).subscribe(response => {
    // });
    // }
  }
}
