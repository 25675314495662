import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[inputText]'
})
export class InputTextDirective {
  @Input() allowType: string;

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    const onlydigitsRegex: RegExp = /[^0-9]*/g;
    const onlytextsRegex: RegExp = /[^A-Za-z ]*/g;
    const digitswthdotRegex: RegExp = /[^0-9.]*/g;
    const textdigitsRegex: RegExp = /[^0-9A-Za-z ]*/g;
    const textdigitspclRegex: RegExp = /[^0-9A-Za-z \-_.]*/g;
    const textemailRegex: RegExp = /[^0-9A-Za-z\-_\+.@]*/g;
    const textdigitinvRegex: RegExp = /[^0-9A-Za-z &\-\+_><\(\).]*/g;
    const addressinputRegex: RegExp = /[^0-9A-Za-z \-_.,/:]*/g;

    if (this.allowType === 'ONLYDIGITS') {
      this._el.nativeElement.value = initalValue.replace(onlydigitsRegex, '');
    } else if (this.allowType === 'ONLYTEXTS') {
      this._el.nativeElement.value = initalValue.replace(onlytextsRegex, '');
    } else if (this.allowType === 'DIGITSWTHDOT') {
      this._el.nativeElement.value = initalValue.replace(digitswthdotRegex, '');
    } else if (this.allowType === 'TEXTDIGITS') {
      this._el.nativeElement.value = initalValue.replace(textdigitsRegex, '');
    } else if (this.allowType === 'TEXTDIGITSPCL') {
      this._el.nativeElement.value = initalValue.replace(textdigitspclRegex, '');
    } else if (this.allowType === 'TEXTEMAIL') {
      this._el.nativeElement.value = initalValue.replace(textemailRegex, '');
    } else if (this.allowType === 'TEXTDIGITINV') {
      this._el.nativeElement.value = initalValue.replace(textdigitinvRegex, '');
    } else if (this.allowType === 'ADDRESSINPUT') {
      this._el.nativeElement.value = initalValue.replace(addressinputRegex, '');
    }
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
