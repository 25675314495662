import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared-module/common-service.service';
import { Constants } from 'src/app/shared-module/constants';
import { GlobalResponse } from 'src/app/shared-module/models';
import { Doctor } from 'src/app/shared-module/models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DoctorService } from 'src/app/shared-module/services/doctor.service';

@Component({
  selector: 'app-subscription-expiry-page',
  templateUrl: './subscription-expiry-page.component.html',
  styleUrls: ['./subscription-expiry-page.component.css']
})
export class SubscriptionExpiryPageComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  doctor: Doctor;
  doctorProfile: any = {};
  plans = [
    {
      title: 'ESSENTIAL',
      cost: '₹9,999/ Year',
      details: [
        'Unlimited Doctor',
        'Vaccination',
        'Follow-up reminder',
        'Inventory management solution',
        'Store Integration for common inventory',
        'Accounting Management',
        'Free customer app'
      ]
    },
    {
      title: 'PROFESSIONAL',
      cost: '₹12,999/ Year',
      details: [
        'Unlimited Doctor',
        'Vaccination',
        'Follow-up reminder',
        'Inventory management solution',
        'Store Integration for common inventory',
        'Accounting Management',
        'Free customer app',
        '#Appointment Management',
        'Bar Code Scanner'
      ]
    }
  ];

  constructor(
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private docService: DoctorService,
    private commService: CommonService
  ) {}

  ngOnInit() {
    this.fetchDoctorDetails();
  }

  fetchDoctorDetails() {
    this.doctor = JSON.parse(localStorage.getItem('login'));
    this.blockUI.start('Fetching details...');
    this.docService.getDoctorDetails(this.doctor._id).subscribe(
      (res: any) => {
        this.blockUI.stop();
        if (res && res.message === Constants.STATUS_SUCCESS) {
          this.doctorProfile = res.result;
          console.log(this.doctorProfile);

          this.commService.setCurrentUserValue(this.doctorProfile);
          localStorage.setItem('login', JSON.stringify(this.doctorProfile));
        }
      },
      error => {
        this.blockUI.stop();
        if (error && error.error.error && error.errors === 'Unauthorized access.') {
          this.toastr.error('Session timedout');
          localStorage.removeItem('login');
          this.router.navigate([Constants.LOGIN_URL]);
        } else {
          this.toastr.error('Internal server error, please try again later');
        }
      }
    );
  }

  logout() {
    localStorage.removeItem('login');
    localStorage.removeItem('token');
    this.commonService.doctorList = [];
    this.commonService.logOut().subscribe((res: GlobalResponse) => {
      if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
        this.toastr.success(Constants.SUCCESS_LOGOUT);
        this.router.navigate([Constants.LOGIN_URL]);
      }
    });
  }
}
