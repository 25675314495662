import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common-service.service';
import { Constants } from '../constants';
import { Doctor, GlobalResponse } from '../models';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit, AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  showUPIReference = false;

  paymentModes = {
    cash: false,
    card: false,
    upi: true,
    netBanking: false
  };

  cashAmount = 0;
  cardAmount = 0;
  upiAmount = 0;
  netBankingAmount = 0;

  upiRef: string;
  cardNo: string;
  notes: string;

  showReferenceInput = false;
  showCardReferenceInput = false;
  showNotesMoreInfo = false;

  public paymentAmount: number;
  public payingAmount: number;
  public pendingAmount: any;
  public advanceAmount = 0;
  public grandTotal: number;
  public invoiceId: string;
  public doctor: Doctor;
  public navigateURL: string;
  public paymentType: string;
  public confirmCheckboxLabel = '';
  public checkboxSelection = false;
  @ViewChild('UPIAmount', { static: false }) private elementRef: ElementRef;
  @BlockUI() blockUI: NgBlockUI;
  toggleUpiReference() {
    this.showUPIReference = !this.showUPIReference;
  }

  ngOnInit() {
    this.paymentAmount = this.grandTotal - this.advanceAmount;
    this.pendingAmount = this.paymentAmount;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paymentModes.upi && this.elementRef.nativeElement) {
        this.elementRef.nativeElement.focus();
      }
    }, 350);
  }

  onCheckboxChange(mode: string) {
    const isAnySelected = Object.values(this.paymentModes).some(value => value);

    if (!isAnySelected) {
      this.paymentModes[mode] = true;
    }
    if (!this.paymentModes.cash) {
      this.cashAmount = 0;
      this.validateAmount('cash');
    }
    if (!this.paymentModes.card) {
      this.cardAmount = 0;
      this.validateAmount('card');
    }
    if (!this.paymentModes.upi) {
      this.upiAmount = 0;
      this.validateAmount('upi');
    }
    if (!this.paymentModes.netBanking) {
      this.netBankingAmount = 0;
      this.validateAmount('netBanking');
    }
  }

  validateAmount(mode: string) {
    const totalPayingAmount =
      (mode !== 'upi' ? +this.upiAmount : 0) +
      (mode !== 'cash' ? +this.cashAmount : 0) +
      (mode !== 'card' ? +this.cardAmount : 0) +
      (mode !== 'netBanking' ? +this.netBankingAmount : 0);

    const maxAllowed = this.paymentAmount - totalPayingAmount;
    if (mode === 'upi' && +this.upiAmount > maxAllowed) {
      this.upiAmount = maxAllowed;
    } else if (mode === 'cash' && +this.cashAmount > maxAllowed) {
      this.cashAmount = maxAllowed;
    } else if (mode === 'card' && +this.cardAmount > maxAllowed) {
      this.cardAmount = maxAllowed;
    } else if (mode === 'netBanking' && +this.netBankingAmount > maxAllowed) {
      this.netBankingAmount = maxAllowed;
    }

    this.changeAmount();
  }

  changeAmount() {
    if (Number(this.paymentAmount) + Number(this.advanceAmount) >= this.grandTotal) {
      this.paymentAmount = this.grandTotal - Number(this.advanceAmount);

      this.payingAmount =
        (+this.cardAmount || 0) + (+this.cashAmount || 0) + (+this.upiAmount || 0) + (+this.netBankingAmount || 0);

      if (this.payingAmount > this.paymentAmount) {
        const excessAmount = this.payingAmount - this.paymentAmount;

        if (this.netBankingAmount >= excessAmount) {
          this.netBankingAmount -= excessAmount;
        } else if (this.upiAmount >= excessAmount) {
          this.upiAmount -= excessAmount;
        } else if (this.cardAmount >= excessAmount) {
          this.cardAmount -= excessAmount;
        } else if (this.cashAmount >= excessAmount) {
          this.cashAmount -= excessAmount;
        }

        this.payingAmount =
          (+this.cardAmount || 0) + (+this.cashAmount || 0) + (+this.upiAmount || 0) + (+this.netBankingAmount || 0);
      }

      this.pendingAmount = (Number(this.paymentAmount) - Number(this.payingAmount)).toFixed(2);
    } else {
      this.pendingAmount = (
        this.grandTotal -
        Number(this.paymentAmount) -
        Number(this.advanceAmount) -
        this.payingAmount
      ).toFixed(2);
    }
  }

  confirmPayment() {
    if (this.paymentModes) {
      const payments: {
        paymentMethod: string;
        amount: number;
        transactionReferenceNo?: string;
        lastFourCardDigits?: string;
      }[] = [];

      if (this.cashAmount > 0) {
        payments.push({
          paymentMethod: 'CASH',
          amount: this.cashAmount
        });
      }
      if (this.cardAmount > 0) {
        payments.push({
          paymentMethod: 'CARD',
          amount: this.cardAmount,
          lastFourCardDigits: this.cardNo
        });
      }
      if (this.upiAmount > 0) {
        payments.push({
          paymentMethod: 'UPI',
          amount: this.upiAmount,
          transactionReferenceNo: this.upiRef
        });
      }
      if (this.netBankingAmount > 0) {
        payments.push({
          paymentMethod: 'NETBANKING',
          amount: this.netBankingAmount
        });
      }
      if (this.payingAmount && this.payingAmount > 0) {
        this.blockUI.start();

        let req_obj = {};
        req_obj = {
          invoiceId: this.invoiceId,
          payingAmount: this.payingAmount,
          payments,
          notes: this.notes
        };
        this.commonService.updateTodayCheckupPayment(this.doctor, req_obj).subscribe(
          (res: GlobalResponse) => {
            this.blockUI.stop();
            this.toastr.success(Constants.SUCCESS_PAYMENT_DONE);
            if (this.checkboxSelection && this.paymentType === 'bill') {
              this.dialogRef.close('doaction');
            } else {
              this.dialogRef.close(true);
            }
            if (this.navigateURL && this.navigateURL.length > 1) {
              this.router.navigate([this.navigateURL]);
            }
          },
          error => {
            this.blockUI.stop();
            this.dialogRef.close(true);
            this.toastr.error(error.message);
          }
        );
      } else {
        this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
      }
    } else {
      this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
    }
  }
}
