<div class="prescription-container">
  <button class="mb-3 oval btn payBlue downloadButton fa-download fa" (click)="actionButton('download')">
    &nbsp;&nbsp;Download
  </button>
  <button class="mb-3 oval btn payBlue printButton fa fa-print" (click)="actionButton('print')">
    &nbsp;&nbsp;Print
  </button>
  <div class="main">
    <div class="prescriptionMainDiv" id="prescription" #prescription>
      <img
        alt="Logo"
        [src]="mainData?.clinic?.logo_url"
        class="prescription-image"
        *ngIf="mainData.clinic && mainData.clinic.logo_url"
      />
      <span class="clinic-name">{{ mainData?.clinic?.name }}</span>
      <div class="clinic-group address">
        <div>
          <span class="address header-icon fa fa-map-marker"></span>
          <span class="address header-text">{{ mainData?.clinic?.address }}</span>
        </div>
        <div class="gst-licence">
          <div class="gst-line-item">
            <span class="mobile header-icon font-weight-bold">Ph: </span>
            <span class="mobile header-text">{{ mainData?.clinic?.phone_no }}</span
            ><span class="mobile header-text" *ngIf="mainData?.clinic?.phone_no2"
              >/ {{ mainData?.clinic?.phone_no2 }}</span
            >
          </div>
          <div class="gst-line-item" *ngIf="mainData?.clinic?.website">
            <span class="website header-icon font-weight-bold"> ||&nbsp;&nbsp;&nbsp;&nbsp;W: </span>
            <span class="website header-text"> {{ mainData?.clinic?.website }}</span>
          </div>
          <div class="gst-line-item" *ngIf="mainData?.clinic?.email_id">
            <span class="email header-icon font-weight-bold"> ||&nbsp;&nbsp;&nbsp;&nbsp;E: </span>
            <span class="email header-text"> {{ mainData?.clinic?.email_id }}</span>
          </div>
        </div>
        <div class="gst-licence">
          <div class="gst-line-item" *ngIf="mainData.clinic && mainData.clinic.clinic_gst">
            <span class="gst header-icon font-weight-bold">GST: </span>
            <span class="gst header-text"> {{ mainData?.clinic?.clinic_gst }}</span>
          </div>
          <div class="gst-line-item" *ngIf="mainData.clinic && mainData.clinic.license_number">
            <span class="license header-icon font-weight-bold"> ||&nbsp;&nbsp;&nbsp;&nbsp;License: </span>
            <span class="license header-text"> {{ mainData?.clinic?.license_number }} </span>
          </div>
        </div>
      </div>
      <div class="pet-details-section row">
        <div class="pet-group first col-sm">
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Pet Name: </span>
            <span class="pet-text">{{ mainData?.pet?.name }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Owner Name: </span>
            <span class="pet-text">{{ mainData?.customer?.first_name }} {{ mainData?.customer?.last_name }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Department: </span>
            <span class="pet-text">{{ mainData?.checkup?.department }}</span>
          </div>
          <div
            class="pet-section"
            *ngIf="mainData.checkup && mainData.checkup.department && mainData.checkup.department === 'IPD'"
          >
            <span class="pet-text font-weight-bold">Check In Date: </span>
            <span class="pet-text">{{ mainData?.checkup?.check_in_date | date: 'dd-MM-yyyy' }}</span>
          </div>
        </div>
        <div class="pet-group second col-sm">
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Pet ID: </span>
            <span class="pet-text">{{ mainData?.pet?.pet_id }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Owner Phone No: </span>
            <span class="pet-text">{{ mainData?.customer?.phone_no }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Doctor Name: </span>
            <span class="pet-text"
              >{{ mainData?.doctor?.salutation || 'Dr.' }} {{ mainData?.doctor?.first_name }}
              {{ mainData?.doctor?.last_name }}</span
            >
          </div>
        </div>
        <div class="pet-group third col-sm">
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Date: </span>
            <span class="pet-text">{{ mainData?.prescription_date | date: 'dd-MM-yyyy' }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Prescription No: </span>
            <span class="pet-text">{{ mainData?.prescription_no }}</span>
          </div>
          <div class="pet-section">
            <span class="pet-text font-weight-bold">Channel: </span>
            <span class="pet-text">{{ mainData?.checkup?.channel }}</span>
          </div>
          <div
            class="pet-section"
            *ngIf="mainData.checkup && mainData.checkup.department && mainData.checkup.department === 'IPD'"
          >
            <span class="pet-text font-weight-bold">Check Out Date: </span>
            <span class="pet-text">{{ mainData?.checkup?.check_out_date | date: 'dd-MM-yyyy' }}</span>
          </div>
        </div>
      </div>
      <div class="bill-group" id="mainBillDetails">
        <div class="bill-group-header row m-0" *ngIf="this.rowData.length > 0">
          <span class="bill-group-text header col-1">#</span>
          <span class="bill-group-text header col-4">Medicine</span>
          <span class="bill-group-text header col-2">Dose</span>
          <span class="bill-group-text header col-5">Duration-Instructions</span>
        </div>
        <div class="bill-group-body row" *ngFor="let item of this.rowData; let index = index">
          <span class="bill-group-text body col-1">{{ index + 1 }}</span>
          <span class="bill-group-text body col-4">{{ item?.medicine.toLowerCase() }}</span>
          <span class="bill-group-text description col-2">
            {{ item?.dose }}
          </span>
          <span class="bill-group-text body col-5">{{ item?.durationIns }}</span>
        </div>
        <h5 class="no-prescription ml-3" *ngIf="!this.rowData || this.rowData.length == 0">
          Prescription is unavailable for this visit as it has either been communicated orally by the doctor, or the
          purpose of the visit didn't warrant any prescription.
        </h5>
        <div
          class="vital-section"
          *ngIf="
            mainData?.purpose_of_visit &&
            mainData?.purpose_of_visit[0] &&
            mainData?.purpose_of_visit[0]?.medical_history
          "
        >
          <div class="vital-header">
            <div class="pet-group first col-sm">
              <div class="pet-section text-center">
                <span class="vital-text">Vitals</span>
                <!-- <span class="pet-text">{{mainData?.pet?.name}}</span> -->
              </div>
            </div>
          </div>
          <div class="vital-body row">
            <div class="pet-group first col-sm">
              <div class="pet-section">
                <span class="pet-text font-weight-bold"
                  >Weight <span class="text-smaller font-weight-normal">(kg)</span>:
                </span>
                <span class="pet-text">{{ mainData?.purpose_of_visit[0]?.medical_history?.weight }}</span>
              </div>
              <div class="pet-section">
                <span class="pet-text font-weight-bold"
                  >Temperature <span class="text-smaller font-weight-normal">(Fahrenheit)</span>:
                </span>
                <span class="pet-text">{{ mainData?.purpose_of_visit[0]?.medical_history?.temperature }}</span>
              </div>
              <div class="pet-section">
                <span class="pet-text font-weight-bold">Respiratory Rate: </span>
                <span class="pet-text">{{ mainData?.purpose_of_visit[0]?.medical_history?.respiratory_rate }}</span>
              </div>
              <div class="pet-section">
                <span class="pet-text font-weight-bold">Others: </span>
                <span class="pet-text">{{ mainData?.purpose_of_visit[0]?.medical_history?.others }}</span>
              </div>
            </div>
            <div class="pet-group second col-sm">
              <div class="pet-section">
                <span class="pet-text font-weight-bold">Heart Rate: </span>
                <span class="pet-text">{{ mainData?.purpose_of_visit[0]?.medical_history?.heart_rate }}</span>
              </div>
              <div class="pet-section">
                <span class="pet-text font-weight-bold">Pulse: </span>
                <span class="pet-text">{{ mainData?.purpose_of_visit[0]?.medical_history?.pulse }}</span>
              </div>
              <div class="pet-section">
                <span class="pet-text font-weight-bold">Clinical Examination: </span>
                <span class="pet-text healthData">{{ mainData?.purpose_of_visit[0]?.medical_history?.health }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="line-of-treatment mt-3" *ngIf="lineOfTreatments">
          <h4 class="text-center text-bold text-underline">Line Of Treatment</h4>
          <p class="pet-section col-12 row pt-2">
            <span class="pet-text font-weight-bold col-md-auto">P.E. Findings & Status:</span>
            {{ lineOfTreatments.pet_findings_status }}
          </p>
          <div class="pet-section ml-5" *ngIf="lineOfTreatments.line_of_treatments">
            <div
              class="line-of-treat-items"
              *ngFor="let ltItem of lineOfTreatments.line_of_treatments; let index = index"
            >
              <h5>
                <span class="text-underline">Treatement {{ index + 1 }}:</span>
                <span class="text-smaller text-muted">({{ ltItem.updated_at | date: 'dd-MM-yyyy hh:mm a' }})</span>
              </h5>
              <pre>{{ ltItem.description }}</pre>
            </div>
          </div>
        </div>
        <div class="bill-group-footer row">
          <div
            class="pet-section col-12 row pt-4"
            *ngIf="
              mainData.checkup &&
              mainData.checkup.next_checkup_date &&
              mainData.checkup.next_checkup_date.length > 0 &&
              mainData.checkup.next_checkup_date != null
            "
          >
            <span class="pet-text font-weight-bold col-2">Next Checkup Date:</span>
            <span class="pet-text font-weight-bold col-2">{{
              mainData?.checkup?.next_checkup_date[0].next_checkup_date | date: 'dd-MM-yyyy'
            }}</span>
          </div>
          <div
            class="pet-section col-12 row pt-4"
            *ngIf="mainData.checkup && mainData.checkup.doctor_notes && mainData.checkup.doctor_notes != null"
          >
            <span class="pet-text font-weight-bold col-7">Doctor Notes:</span>
          </div>
          <div
            class="pet-section col-12 row pt-2"
            *ngIf="mainData.checkup && mainData.checkup.doctor_notes && mainData.checkup.doctor_notes != null"
          >
            <span class="pet-text col-12">{{ mainData?.checkup?.doctor_notes }}</span>
          </div>
        </div>
        <div class="main-footer">
          <div class="d-flex justify-content-center pawsnme-text">Powered by PawsNme</div>
          <span class="d-flex justify-content-center">
            This is Computer Generated Prescription, No Signature Required
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<block-ui></block-ui>
