<span class="certificateTitle">Vaccination Certificate</span>
<div class="mainContents">
  <p class="contents">
    This certifies that the Pet named <span class="cert-elements">{{ certificateDetails?.pet?.name }} </span> (referred
    to as "Pet"), a <em>{{ certificateDetails?.pet.sex }}</em>
    <span class="cert-elements"> {{ certificateDetails?.pet.species }}</span> of the
    <em> {{ certificateDetails?.pet.breed.name }}</em> breed,
    <span *ngIf="certificateDetails?.pet?.dob">
      aged : <span class="cert-elements">{{ certificateDetails?.pet?.dob | age: 'DD/MM/YYYY' }}</span
      >,
    </span>
    identified by
    <span *ngIf="certificateDetails?.microchipNo">
      <span class="cert-elements"
        >Microchip No: <em>{{ certificateDetails?.microchipNo }}</em></span
      >
      and
    </span>
    <span class="cert-elements"
      >Pet ID: <em>{{ certificateDetails?.pet.petid }}</em></span
    >, owned by
    <span class="cert-elements"
      >{{ certificateDetails?.customer.first_name }} {{ certificateDetails?.customer.last_name }}
    </span>
    <span *ngIf="certificateDetails?.address"
      >, residing at <em>{{ certificateDetails?.address }}</em></span
    >, has received the vaccination of <span class="cert-elements">{{ certificateDetails?.vaccineName }} </span> on
    <span class="cert-elements"
      ><em>{{ certificateDetails?.fromMonth | monthName }}-{{ certificateDetails?.fromYear }}</em></span
    >, expiring on
    <span class="cert-elements"
      ><em>{{ certificateDetails?.toMonth | monthName }}- {{ certificateDetails?.toYear }}</em></span
    >.
  </p>
  <p class="contents">
    The vaccination were administered by
    <span class="cert-elements">
      {{ certificateDetails?.doctor.salutation }} {{ certificateDetails?.doctor.first_name }}
      {{ certificateDetails?.doctor.last_name }}
    </span>
    at <em>{{ certificateDetails?.clinic.name }}</em
    >, and the Pet is upto date with all mandatory vaccination as of
    <span class="cert-elements">{{ certificateDetails?.updatedAt | date: 'dd/MM/yyyy' }}</span
    >.
  </p>
  <p class="contents">
    I,
    <span class="cert-elements">
      {{ certificateDetails?.doctor.salutation }} {{ certificateDetails?.doctor.first_name }}
      {{ certificateDetails?.doctor.last_name }} </span
    >, confirm that this information is accurate and certify that the Pet is fit and protected as per the latest
    vaccination guidelines.
  </p>
</div>
