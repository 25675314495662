<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="dialogRef.close(false)">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->

<h1 mat-dialog-title class="text-center dialogHeader blueHeader text-white">Payment</h1>
<mat-dialog-content class="mb-4">
  <div class="col-md-12 text-center amountBox">
    <div class="col-md-6">
      <p id="totalAmount">
        <b
          >To Pay:<span style="color: green">{{ paymentAmount }}</span></b
        >
      </p>
    </div>
    <div class="col-md-6">
      <p id="toPay">
        <b
          >Pending:<span style="color: red">{{ pendingAmount }}</span>
        </b>
      </p>
    </div>
  </div>
  <div class="paymentType text-center">
    <span class="required payoption">Payment Options</span>
    <div class="payment-option mt-2">
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.cash"
          value="CASH"
          id="cash"
          name="payment"
          class="custom-control-input"
          (change)="amountSelect()"
        />
        <label class="custom-control-label" for="cash">Cash</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.card"
          value="CARD"
          id="card"
          name="payment"
          class="custom-control-input"
          (change)="amountSelect()"
        />
        <label class="custom-control-label" for="card">Card</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.upi"
          value="UPI"
          id="upi"
          name="payment"
          class="custom-control-input"
          (change)="amountSelect()"
        />
        <label class="custom-control-label" for="upi">UPI</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.netBanking"
          value="NET-BANKING"
          id="netbanking"
          name="payment"
          class="custom-control-input"
          (change)="amountSelect()"
        />
        <label class="custom-control-label" for="netbanking">Net Banking</label>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <!-- <div class="paymentDiv col-md-12 mb-2">
      <label for="paymentAmount" class="payingClinic col-md-6 required">Paying(&#8377;) in Clinic</label>
      <input
        type="text"
        inputText
        allowType="DIGITSWTHDOT"
        id="paymentAmount"
        [(ngModel)]="paymentAmount"
        class="form-control input-font paymentAmount discountBox col-md-6"
        (change)="changeAmount()"
      />
    </div>
    <div class="pendingDiv col-md-12 mb-2">
      <label for="pendingAmount" class="toPay col-md-6 required">Pending(&#8377;) to Pay in App</label>
      <input
        type="text"
        class="form-control input-font pendingAmount discountBox col-md-6"
        id="pendingAmount"
        [(ngModel)]="pendingAmount"
        readonly
      />
    </div> -->
    <div class="pendingDiv col-md-12 mb-2">
      <label for="advanceAmount" class="toPay col-md-4 required">Advance Paid</label>
      <input
        type="text"
        class="form-control input-font pendingAmount discountBox col-md-8"
        id="advanceAmount"
        [(ngModel)]="advanceAmount"
        readonly
      />
    </div>
    <!-- Cash Amount -->
    <ng-container *ngIf="paymentModes.cash">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">Cash Amount</label>
        <input
          selectText
          type="text"
          class="form-control input-font pendingAmount discountBox col-md-8"
          id="cash-amount"
          placeholder="enter amount"
          [(ngModel)]="cashAmount"
          (keyup)="changeAmount()"
        />
      </div>
    </ng-container>
    <!-- Card Amount -->
    <ng-container *ngIf="paymentModes.card">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">Card Amount</label>
        <input
          selectText
          type="text"
          class="form-control input-font pendingAmount discountBox col-md-8"
          id="card-amount"
          placeholder="enter amount"
          [(ngModel)]="cardAmount"
          (keyup)="changeAmount()"
        />
      </div>
    </ng-container>
    <!-- UPI Amount  -->
    <ng-container *ngIf="paymentModes.upi">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">UPI Amount</label>
        <input
          selectText
          type="text"
          class="form-control input-font pendingAmount discountBox col-md-8"
          id="upi-amount"
          placeholder="enter amount"
          [(ngModel)]="upiAmount"
          (keyup)="changeAmount()"
        />
      </div>
    </ng-container>
    <!-- Net Banking Amount -->
    <ng-container *ngIf="paymentModes.netBanking">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">Net Banking Amount</label>
        <input
          selectText
          type="text"
          class="form-control input-font pendingAmount discountBox col-md-8"
          id="netbanking-amount"
          placeholder="enter amount"
          [(ngModel)]="netbankingAmount"
          (change)="changeAmount()"
          (keyup)="changeAmount()"
        />
      </div>
    </ng-container>
    <section *ngIf="invoiceId && invoiceId.length > 0 && paymentType === 'bill'" class="text-center pt-4">
      <input type="checkbox" id="checkboxSelection" [(ngModel)]="checkboxSelection" />
      <label for="checkboxSelection" class="text-info checkboxSelection ml-2">{{ confirmCheckboxLabel }}</label>
    </section>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="mat-raised-button mr-4 btn blankTurquoise btnWidth"
      (click)="dialogRef.close(false)"
      title="Click to cancel payment"
    >
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth"
      (click)="confirmPayment()"
      title="Click to confirm payment"
    >
      Confirm
    </button>
  </div>
</mat-dialog-actions>
