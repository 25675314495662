import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../shared-module/constants';
import { CommonService } from '../shared-module/common-service.service';
import { Observable } from 'rxjs';
import { ICreateCertificateRequest } from './models/create-certificate-req-resp';
import { ICertificate } from './models/certificate';

@Injectable({
  providedIn: 'root'
})
export class PetCertificatesServiceService {
  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  fetchCertificateList(
    pageIndex: number,
    pageSize: number,
    fromDate: any,
    toDate: any,
    q: string = '',
    doctorID: string = '',
    certificateType: ICertificate
  ) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_FROM_DATE, fromDate.year + '-' + fromDate.month + '-' + fromDate.day)
      .set(Constants.QUERY_PARAMS_TO_DATE, toDate.year + '-' + toDate.month + '-' + toDate.day)
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, pageSize.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageIndex.toString())
      .set(Constants.QUERY_PARAMS_CERTIFICATE_TYPE, certificateType ? certificateType.value : '')
      .set(Constants.QUERY_PARAMS_DOCTORID, doctorID)
      .set(Constants.QUERY_PARAMS_Q, q);
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.CERTIFICATE_URL +
        Constants.PET_CERTIFICATE_URL +
        Constants.CLINIC_API +
        this.commonService.doctorValue.clinics[0].clinic._id,
      { params }
    );
  }

  createNewCertificate(certificatePayload: ICreateCertificateRequest) {
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.CERTIFICATE_URL +
        Constants.PET_CERTIFICATE_URL +
        Constants.CLINIC_API +
        this.commonService.doctorValue.clinics[0].clinic._id,
      certificatePayload
    );
  }

  deleteCertificate(certificateId: string) {
    return this.httpClient.delete(
      Constants.MAIN_ENV_URL +
        Constants.CERTIFICATE_URL +
        Constants.PET_CERTIFICATE_URL +
        certificateId +
        Constants.URL_DASH +
        Constants.CLINIC_API +
        this.commonService.doctorValue.clinics[0].clinic._id
    );
  }

  // Fetch certificate details by ID
  getCertificateById(certificateId: string): Observable<any> {
    return this.httpClient.get(
      Constants.MAIN_ENV_URL + Constants.CERTIFICATE_URL + Constants.PET_CERTIFICATE_URL + certificateId
    );
  }

  // Update the certificate
  updateExistingCertificate(certificateId: string, certificateDetails: any): Observable<any> {
    return this.httpClient.patch(
      Constants.MAIN_ENV_URL +
        Constants.CERTIFICATE_URL +
        Constants.PET_CERTIFICATE_URL +
        certificateId +
        Constants.URL_DASH +
        Constants.CLINIC_API +
        this.commonService.doctorValue.clinics[0].clinic._id,
      certificateDetails
    );
  }

  formatCertificateName(certificateName: string): string {
    return certificateName
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.toLowerCase().charAt(0).toUpperCase() + word.toLowerCase().slice(1))
      .join(' ');
  }
}
