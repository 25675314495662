<span class="certificateTitle">Spayed Neuter Surgery Procedure Certificate</span>
<div class="mainContents">
  <p class="contents">
    This document serves as confirmation that consent has been given for the pet named
    <span class="cert-elements">{{ certificateDetails?.pet.name }} </span> (referred to as "Pet"), a
    <em>{{ certificateDetails?.pet.sex }}</em>
    <span class="cert-elements">
      {{ certificateDetails?.pet.species }}
    </span>
    of the <em> {{ certificateDetails?.pet.breed.name }}</em> breed,
    <span *ngIf="certificateDetails?.pet?.dob">
      aged <span class="cert-elements">{{ certificateDetails?.pet?.dob | age: 'DD/MM/YYYY' }}</span> </span
    >, identified by
    <span *ngIf="certificateDetails?.microchipNo">
      <span class="cert-elements">
        Microchip No: <em>{{ certificateDetails?.microchipNo }} </em>
      </span>
      and
    </span>
    <span class="cert-elements"
      >Pet ID: <em>{{ certificateDetails?.pet.petid }} </em> </span
    >, owned by
    <span class="cert-elements"
      >{{ certificateDetails?.customer.first_name }} {{ certificateDetails?.customer.last_name }} </span
    >, residing at <em>{{ certificateDetails?.address }}</em
    >, to undergo a surgical procedure. <br />
    <br />
    The Pet has been operated on for the purpose of
    <span class="cert-elements">{{ certificateDetails?.surgicalProcedure }} </span> by the attending veterinarian.
  </p>
</div>
