<span class="certificateTitle">EUTHANASIA CONSENT FORM</span>
<div class="mainContents">
  <p class="contents">
    I,
    <span class="cert-elements"
      >{{ certificateDetails?.customer.first_name }} {{ certificateDetails?.customer.last_name }}
    </span>
    (referred to as "Owner"), certify that I am the legal owner, or an authorized agent acting on behalf of the legal
    owner, of the Pet named <span class="cert-elements">{{ certificateDetails?.pet.name }} </span> (referred to as
    "Pet"), a <em>{{ certificateDetails?.pet.sex }}</em>
    <span class="cert-elements"> {{ certificateDetails?.pet.species }}</span> of the
    <em> {{ certificateDetails?.pet.breed.name }}</em> breed,
    <span *ngIf="certificateDetails?.pet?.dob">
      aged <span class="cert-elements">{{ certificateDetails?.pet?.dob | age: 'DD/MM/YYYY' }}</span
      >,
    </span>
    identified by
    <span *ngIf="certificateDetails?.microchipNo"
      ><span class="cert-elements"
        >Microchip No: <em>{{ certificateDetails?.microchipNo }}</em></span
      >
      and
    </span>
    <span class="cert-elements"
      >Pet ID: <em>{{ certificateDetails?.pet.petid }}</em></span
    >. <br />
    <br />
    I hereby give my consent and authorize the euthanasia of the said Pet,
    <span class="cert-elements"
      ><em>{{ certificateDetails?.pet.name }}</em> </span
    >. To the best of my knowledge and belief, the Pet has not bitten any person or animal within the last fifteen 15
    days and has not been exposed to rabies. I grant the attending veterinarian, along with their agents and
    representatives, full authority to perform the euthanasia and to dispose of the Pet in a humane manner, in
    accordance with the rules and regulations of the facility. <br /><br />
    Furthermore, I release the veterinarian, as well as their agents and representatives, from any and all liability
    associated with the euthanasia of
    <span class="cert-elements"
      ><em>{{ certificateDetails?.pet.name }}</em> </span
    >.
  </p>
</div>
