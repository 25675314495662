<span class="certificateTitle">Health Certificate</span>
<div class="mainContents">
  <p class="contents">
    This is to certify that
    <span class="cert-elements">{{ certificateDetails?.pet.name }} </span> (hereafter referred to as "Pet"), a
    <em>{{ certificateDetails?.pet.sex }}</em>
    <span class="cert-elements"> {{ certificateDetails?.pet.species }} </span> of
    <em> {{ certificateDetails?.pet.breed.name }}</em> breed,
    <span *ngIf="certificateDetails?.pet?.dob">
      aged <span class="cert-elements">{{ certificateDetails?.pet?.dob | age: 'DD/MM/YYYY' }} </span>,
    </span>
    identified by
    <span *ngIf="certificateDetails?.microchipNo">
      <span class="cert-elements"
        >Microchip No: <em>{{ certificateDetails?.microchipNo }}</em>
      </span>
      and
    </span>
    <span class="cert-elements">
      Pet ID: <em>{{ certificateDetails?.pet.petid }}</em></span
    >
    and owned by
    <span class="cert-elements"
      >{{ certificateDetails?.customer.first_name }} {{ certificateDetails?.customer.last_name }} </span
    >, residing at <em>{{ certificateDetails?.address }}</em
    >, has been thoroughly examined by a licensed veterinarian. After a detailed assessment, it has been determined that
    the pet shows no visible signs of any contagious diseases or external parasites. The examination was conducted with
    utmost care and in accordance with veterinary health standards.
  </p>
  <p class="contents">
    The health and well-being of the pet remain a priority, and based on the current evaluation,
    <span class="cert-elements">
      <em>{{ certificateDetails?.pet.name }}</em>
    </span>
    is in good health. This certificate is issued to provide reassurance to the pet owner that the pet is free from any
    immediate health concerns that could affect other animals or individuals. has a medical condition and/or will
    undergo a surgical procedure. I acknowledge that this may increase the risks associated with anesthesia, surgery,
    and the recovery process.
  </p>
</div>
