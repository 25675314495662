import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared-module/shared-module.module';

import { CertificateDashboardComponent } from './certificate-dashboard/certificate-dashboard.component';
import { CreateCertificateComponent } from './create-certificate/create-certificate.component';
import { EuthenesiaConsentFormComponent } from './euthenesia-consent-form/euthenesia-consent-form.component';
import { HealthCertificateComponent } from './health-certificate/health-certificate.component';
import { MicrochipCertificateComponent } from './microchip-certificate/microchip-certificate.component';
import { PetCertificatesRoutingModule } from './pet-certificates-routing.module';
import { PetCertificatesServiceService } from './pet-certificates-service.service';
import { PetTravelCertificateComponent } from './pet-travel-certificate/pet-travel-certificate.component';
import { PoweredByFooterComponent } from './powered-by-footer/powered-by-footer.component';
import { SpayedCertificateComponent } from './spayed-certificate/spayed-certificate.component';
import { SurgeryCertificateComponent } from './surgery-certificate/surgery-certificate.component';
import { VaccinationCertificateComponent } from './vaccination-certificate/vaccination-certificate.component';
import { CertificateTemplateComponent } from './certificate-template/certificate-template.component';

@NgModule({
  declarations: [
    SurgeryCertificateComponent,
    EuthenesiaConsentFormComponent,
    HealthCertificateComponent,
    MicrochipCertificateComponent,
    SpayedCertificateComponent,
    PetTravelCertificateComponent,
    VaccinationCertificateComponent,
    PoweredByFooterComponent,
    CertificateDashboardComponent,
    CertificateTemplateComponent,
    CreateCertificateComponent
  ],
  imports: [CommonModule, PetCertificatesRoutingModule, SharedModule],
  providers: [PetCertificatesServiceService]
})
export class PetCertificatesModule {}
