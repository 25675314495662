import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common-service.service';
import { Constants } from '../constants';
import { Doctor, GlobalResponse } from '../models';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {
  payment = Constants.PAYMENT_CASH;

  paymentModes = {
    cash: true,
    card: false,
    upi: false,
    netBanking: false
  };

  cashAmount = 0;
  cardAmount = 0;
  upiAmount = 0;
  netbankingAmount = 0;

  public paymentAmount: number;
  public payingAmount: number;
  public pendingAmount: any;
  public advanceAmount = 0;
  public grandTotal: number;
  public invoiceId: string;
  public doctor: Doctor;
  public navigateURL: string;
  public paymentType: string;
  public confirmCheckboxLabel = '';
  public checkboxSelection = false;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.paymentAmount = this.grandTotal - this.advanceAmount;
    this.pendingAmount = this.paymentAmount;
  }

  // to set the amount to 0 if any checkbox is unchecked
  amountSelect() {
    if (!this.paymentModes.cash) {
      this.cashAmount = 0;
    }
    if (!this.paymentModes.card) {
      this.cardAmount = 0;
    }
    if (!this.paymentModes.upi) {
      this.upiAmount = 0;
    }
    if (!this.paymentModes.netBanking) {
      this.netbankingAmount = 0;
    }
    this.changeAmount();
  }

  changeAmount() {
    if (Number(this.paymentAmount) + Number(this.advanceAmount) >= this.grandTotal) {
      this.paymentAmount = this.grandTotal - Number(this.advanceAmount);

      this.payingAmount =
        (+this.cardAmount || 0) + (+this.cashAmount || 0) + (+this.upiAmount || 0) + (+this.netbankingAmount || 0);

      if (this.payingAmount > this.paymentAmount) {
        this.toastr.error('Paying amount cannot exceed the total payment amount.');
      }

      this.pendingAmount = (Number(this.paymentAmount) - Number(this.payingAmount)).toFixed(2);
    } else {
      this.pendingAmount = (this.grandTotal - Number(this.paymentAmount) - this.payingAmount).toFixed(2);
    }
  }

  confirmPayment() {
    if (this.payment) {
      const payments: { paidAmount: number; paymentMethod: string }[] = [];
      if (this.cashAmount > 0) {
        payments.push({ paidAmount: this.cashAmount, paymentMethod: 'Cash' });
      }
      if (this.cardAmount > 0) {
        payments.push({ paidAmount: this.cardAmount, paymentMethod: 'Card' });
      }
      if (this.upiAmount > 0) {
        payments.push({ paidAmount: this.upiAmount, paymentMethod: 'UPI' });
      }
      if (this.netbankingAmount > 0) {
        payments.push({ paidAmount: this.netbankingAmount, paymentMethod: 'Net Banking' });
      }
      if (this.paymentAmount || this.paymentAmount >= 0) {
        this.blockUI.start();

        let req_obj = {};
        req_obj = {
          invoiceId: this.invoiceId,
          amount: this.payingAmount,
          // paymentMethod: this.payment,
          payments

          // to ask how the value is to be passed here in my patient payment
        };
        this.commonService.updateTodayCheckupPayment(this.doctor, req_obj).subscribe(
          (res: GlobalResponse) => {
            this.blockUI.stop();
            this.toastr.success(Constants.SUCCESS_PAYMENT_DONE);
            if (this.checkboxSelection && this.paymentType === 'bill') {
              this.dialogRef.close('doaction');
            } else {
              this.dialogRef.close(true);
            }
            if (this.navigateURL && this.navigateURL.length > 1) {
              this.router.navigate([this.navigateURL]);
            }
          },
          error => {
            this.blockUI.stop();
            this.dialogRef.close(true);
            this.toastr.error(error.message);
          }
        );
      } else {
        this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
      }
    } else {
      this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
    }
  }
}
