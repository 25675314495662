<span class="certificateTitle">High Risk / Surgery Consent Form</span>
<div class="mainContents">
  <p class="contents">
    This document confirms the consent for the medical or surgical procedure of the pet named
    <span class="cert-elements">{{ certificateDetails?.pet.name }} </span> (referred to as "Pet"), a<em>
      {{ certificateDetails?.pet.sex }}</em
    >
    <span class="cert-elements"> {{ certificateDetails?.pet.species }}</span> of the
    <em> {{ certificateDetails?.pet.breed.name }}</em> breed,
    <span *ngIf="certificateDetails?.pet?.dob">
      aged <span class="cert-elements">{{ certificateDetails?.pet?.dob | age: 'DD/MM/YYYY' }}</span
      >,
    </span>
    identified by
    <span *ngIf="certificateDetails?.microchipNo">
      <span class="cert-elements"
        >Microchip Number:
        <em>{{ certificateDetails?.microchipNo }}</em>
      </span>
      and
    </span>
    <span class="cert-elements"
      >Pet ID: <em>{{ certificateDetails?.pet.petid }}</em>
    </span>
    .
  </p>
  <p class="contents">
    I,
    <span class="cert-elements"
      >{{ certificateDetails?.customer.first_name }} {{ certificateDetails?.customer.last_name }} </span
    >, residing at <em>{{ certificateDetails?.address }}</em
    >, have been fully informed that
    <span class="cert-elements"
      ><em>{{ certificateDetails?.pet.name }}</em></span
    >
    has a medical condition and/or will undergo a surgical procedure that may increase the risks associated with
    anesthesia, surgery, and the recovery process. These risks include, but are not limited to: <br /><br />
    - Extended recovery time from anesthesia <br />
    - Potential worsening of the medical condition <br />
    - Prolonged healing process <br />
    - Risk of death <br /><br />
    Medical Conditions (if any): <span class="cert-elements">{{ certificateDetails?.medicalCondition }}</span> <br />
    Surgical Procedure (if any): <span class="cert-elements">{{ certificateDetails?.surgicalProcedure }}</span>
    <br /><br />

    The specific medical condition and all associated risks have been clearly explained to me. I understand these risks
    and accept full financial responsibility for any complications that may arise during or after the procedure due to
    this condition. <br /><br />
  </p>
</div>
